<script setup lang="ts">
</script>

<template>
  <USelectMenu
    searchable-placeholder="Filtrar..."
    option-attribute="label"
    value-attribute="value"
    :ui-menu="{ ...$attrs?.uiMenu, width: `min-w-max ${$attrs?.uiMenu?.width}` }"
  />
</template>
