import { ViewSettingsModal } from '#components'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import { flatten } from 'flat'
import { get } from 'lodash-es'
import type { ComponentProps } from 'vue-component-type-helpers'
import type { Column } from '~~/types'

export type View = 'table' | 'cards' | 'gallery' | 'inbox' | 'kanban' | 'calendar'

type ViewOption = (Option & { value: View })

export type ViewSettings<T> = {
  tableName: string
  views: ViewOption[]
  selectedView: View
  columns: Column[]
  visibleColumns: Column['key'][]
  // [optional] grouping (used e.g. for kanban view):
  groups?: Option[]
  group?: string
  // [optional] internationalization:
  i18nOptions?: Option[]
  i18n?: string
}

export const OPTIONS_VIEWS: ViewOption[] = [
  { value: 'table', label: 'Tabla', icon: 'i-mdi-table' },
  { value: 'cards', label: 'Tarjetas', icon: 'i-mdi-view-module' },
  { value: 'gallery', label: 'Galería', icon: 'i-mdi-view-grid' },
  { value: 'inbox', label: 'Vista previa', icon: 'i-mdi-view-split-vertical' },
  { value: 'kanban', label: 'Kanban', icon: 'i-mdi-view-column' },
  { value: 'calendar', label: 'Calendario', icon: 'i-mdi-calendar' },
] as const

export const OPTIONS_I18N: Option[] = [
  { value: 'es', label: 'Español' },
  { value: 'en', label: 'English' },
] as const

export const COLUMNS_METADATA: Column[] = [
  {
    key: 'createdAt',
    label: 'Fecha de creación',
    sortable: true,
  },
  {
    key: 'updatedAt',
    label: 'Última actualización',
    sortable: true,
  },
  {
    key: 'createdBy',
    label: 'Creado por',
    sortable: true,
  },
  {
    key: 'updatedBy',
    label: 'Actualizado por',
    sortable: true,
  },
]

// IMPORTANT: useAsyncData/useFetch cannot return null or undefined
export const getUseAsyncDataArgsViewSettings = <T>(key: string, viewSettings: ViewSettings<T>) => [
  key,
  async () => {
    // IMPORTANT: use useRequestFetch() to send cookies along when on the server (during SSR) which
    // $fetch does not do by default at the moment (pending merge https://github.com/nuxt/nuxt/issues/24813)
    const $fetch = useRequestFetch()
    return await $fetch(`/kv/${key}`) ?? viewSettings // no need to persist until updated for first time
  },
  {
    key,
    shallow: false,
    default: () => viewSettings,
  },
]

export function useView<T>(key: string, view: Ref<ViewSettings<T>>) {
  const modal = useModal()

  // NOTE: expose a readonly `columns` built from `view.visibleColumns` to ensure
  // proper column order when toggling columns on/off (otherwise columns lose order)
  const columns = computed<Column[]>(() => {
    const { columns, visibleColumns } = view.value
    return columns.filter(column => visibleColumns.includes(column.key)) ?? []
  })

  const selected = ref<T>([] as T[])

  const modalOpenViewSettings = (props?: ComponentProps<typeof ViewSettingsModal>) => {
    modal.open(ViewSettingsModal, {
      title: props?.title ?? 'Ajustes de la vista',
      description: props?.description ?? 'Ajusta la vista predeterminada a tus necesidades',
      view: props?.view ?? view.value,
      onUpdateView: props?.onUpdateView ?? (async (value: ViewSettings<T>) => {
        view.value = value
        await $fetch(`/kv/${key}`, { method: 'PUT', body: value })
      }),
      disabledFields: props?.disabledFields,
      hiddenFields: props?.hiddenFields,
    })
  }

  return {
    columns,
    selected,
    modalOpenViewSettings,
  }
}

// query:

type ViewQueryFilter = string | string[] | '$isNull' | '$isNotNull' | undefined

// filters: capture as ...filters using rest operator (object required for using ...rest operator)
export type ViewQuery = object & Record<string, ViewQueryFilter> & {
  $search?: { columns: string[], value: string }
  $sort?: { column: string, direction: 'asc' | 'desc' }
  $pagination?: { page: number, pageSize: number }
  $with?: Record<string, boolean | Record<string, unknown>>
}

export function useViewQuery<T extends ViewQuery>(initialQuery: T) {
  const query = ref<T>(structuredClone(initialQuery))
  const isFiltering = computed(() => {
    const { $search, $sort, $pagination, $with, ...allFilters } = query.value
    const { deletedAt, ...filters } = allFilters // exclude special filters
    return Object.entries(filters).some(([key, value]) => {
      if (Array.isArray(value)) return value.length > 0
      if (typeof value === 'string') return ![null, undefined, ''].includes(value)
      return value !== undefined
    })
  })
  const clearFilters = () => query.value = structuredClone(initialQuery)
  return { query, isFiltering, clearFilters }
}

// pagination:

export function useViewPagination<T>(allRows: Ref<T[]>) {
  const items = [
    [
      {
        label: '10',
        click: () => setPageCount(10),
      },
      {
        label: '25',
        click: () => setPageCount(25),
      },
      {
        label: '100',
        click: () => setPageCount(100),
      },
    ],
  ]

  const page = ref(1)

  const pageCount = ref(25)

  const setPageCount = (count: number) => {
    pageCount.value = count
    page.value = 1 // Reset to the first page when page count changes
  }

  const total = computed(() => unref(allRows)?.length)

  const rows = computed(() => {
    return unref(allRows)?.slice(
      (page.value - 1) * pageCount.value,
      page.value * pageCount.value,
    )
  })

  return { rows, items, page, total, pageCount }
}

// download:

export function useViewDownload() {
  // reduce all rows into a rows with column.label as key, and only keep visibleColumns
  const getCsvRows = <T>(columns: Column[], rows: T[]) => {
    const flatData = rows.map(row => flatten(row))
    const cols = columns.filter(column => !['actions'].includes(column.key))
    return flatData.map((originalRow, index) => {
      return cols.reduce((row, col) => {
        let value = get(originalRow, col.key)
        value ??= '' // cast null/undefined values to empty strings "" for export
        row[col.label ?? col.key] = value
        return row
      }, {})
    })
  }

  // see https://medium.com/@j.lilian/how-to-export-react-tanstack-table-to-csv-file-722a22ccd9c5
  function downloadDataAsCsv<T>(
    flatData: T[],
    filename: string,
    columnHeaders?: Array<string | { key: string, displayLabel: string }>,
  ) {
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      filename, // export file name (without .csv)
      decimalSeparator: '.',
      ...(columnHeaders ? { columnHeaders } : { useKeysAsHeaders: true }),
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const csv = generateCsv(csvConfig)<any>(flatData)
    download(csvConfig)(csv)
  }

  return {
    getCsvRows,
    downloadDataAsCsv,
  }
}
