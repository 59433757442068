<script setup lang="ts">
const model = defineModel<boolean>()

const props = defineProps<{
  title?: string
  description?: string
  view: Required<ViewSettings<unknown>>
  onUpdateView: (view: Required<ViewSettings<unknown>>) => void
  disabledFields?: string[]
  hiddenFields?: string[]
}>() // NOTE: vue disallows generics for props e.g. SlideoverProps<T, TabT, SectionT>

const state = ref(props.view)
watch(state, props.onUpdateView, { deep: true })

// const R = createR<typeof props>(props)
const H = createH<typeof props>(props)
const D = createD<typeof props>(props)
</script>

<template>
  <UDashboardModal
    v-model="model"
    :title
    :description
  >
    <UFormGroup
      v-if="!H('fields', 'selectedView')"
      label="Vista"
      name="selectedView"
      required
    >
      <UButtonGroup>
        <UButton
          v-for="(view, i) in state.views"
          :key="`view-${i}-${view.value}`"
          variant="outline"
          :color="state.selectedView === view.value ? 'primary' : 'gray'"
          :icon="view.icon"
          :label="view.label"
          @click="state.selectedView = view.value"
        />
      </UButtonGroup>
    </UFormGroup>

    <!-- SHARED -->
    <UFormGroup
      v-if="state.i18n && !H('fields', 'i18n')"
      label="Idioma"
      name="i18n"
      required
    >
      <SelectMenu
        v-model="state.i18n"
        icon="i-mdi-format-list-group"
        value-attribute="value"
        :options="state.i18nOptions"
        :popper="{ placement: 'bottom-start' }"
        :ui-menu="{ width: 'min-w-max' }"
      />
    </UFormGroup>

    <!-- TABLE -->
    <template v-if="['table'].includes(state.selectedView)">
      <UFormGroup
        v-if="!H('fields', 'visibleColumns')"
        label="Columnas"
        name="visibleColumns"
        required
      >
        <SelectMenu
          v-model="state.visibleColumns"
          icon="i-mdi-table-column"
          :options="state.columns.filter(c => !!c.label)"
          searchable
          searchable-placeholder="Filtrar columnas..."
          multiple
          value-attribute="key"
          :popper="{ placement: 'bottom-start' }"
          :ui-menu="{ width: 'min-w-max' }"
        >
          <template #label>
            {{ state.visibleColumns?.length }}
          </template>
        </SelectMenu>
      </UFormGroup>
    </template>

    <!-- KANBAN -->
    <template v-if="['kanban'].includes(state.selectedView)">
      <UFormGroup
        v-if="!H('fields', 'group')"
        label="Agrupar por"
        name="group"
        required
      >
        <SelectMenu
          v-model="state.group"
          icon="i-mdi-format-list-group"
          value-attribute="value"
          :options="state.groups"
          :popper="{ placement: 'bottom-start' }"
          :ui-menu="{ width: 'min-w-max' }"
        />
      </UFormGroup>
    </template>
  </UDashboardModal>
</template>
