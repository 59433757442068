import type { FormSubmitEvent } from '#ui/types'

export const open = (url: string, target = '_blank') => window?.open(url, target)

// modal/slideover:

// NOTE: vue disallows generics for props e.g. SlideoverProps<T, TabT, SectionT>
// so we cannot define a single generic type here for all Modal/Slideover components to import, since we have
// to specify prop types inline e.g. { data: defineProps<{...}>() } but we keep this here for reference
// see https://vuejs.org/api/sfc-script-setup.html#type-only-props-emit-declarations
// see https://stackoverflow.com/a/76473359/23545143
type ModalOrSlideoverProps = {
  data: Partial<Notification>
  onSubmit: (event: FormSubmitEvent<Partial<Notification>>) => void
  title?: string
  tab?: string
  disabledTabs?: string[]
  hiddenTabs?: string[]
  hiddenSections?: string[]
  requiredFields: (keyof Notification | string)[]
  hiddenFields?: (keyof Notification | string)[]
  disabledFields?: (keyof Notification | string)[]
}

export const createH = <T extends ModalOrSlideoverProps>(props: T) => (
  type: 'tabs' | 'sections' | 'fields',
  key: string,
) => {
  if (type === 'tabs') return props.hiddenTabs?.includes(key as Extract<T, 'hiddenTabs'>)
  if (type === 'sections') return props.hiddenSections?.includes(key as Extract<T, 'hiddenSections'>)
  if (type === 'fields') return props.hiddenFields?.includes(key as Extract<T, 'hiddenFields'>)
  return false
}

export const createD = <T extends ModalOrSlideoverProps>(props: T) => (
  type: 'tabs' | 'fields',
  key: string,
) => {
  if (type === 'tabs') return props.disabledTabs?.includes(key as Extract<T, 'disabledTabs'>)
  if (type === 'fields') return props.disabledFields?.includes(key as Extract<T, 'disabledFields'>)
  return false
}

/**
 * WORKAROUND: this is a hack to close the modal and slideover before
 * opening another one since nested modals/slideovers are not supported
 * in @nuxt/ui v2 due to a current limitation with HeadlessUI Dialog,
 *
 * TODO: remove this utility once nested modals/slideovers supported in v3
 *
 * @see https://github.com/netzo/esgraf-app/issues/22
 */
export const openNested = async (callback: () => void) => {
  useModal().close() // either
  useSlideover().close() // or
  await new Promise(res => setTimeout(res, 900)) // wait at least closing transition duration (not more, 900ms works)
  callback()
}

// view:

// view-table:

// view-cards:

// view-inbox:

// view-kanban:

// view-calendar:
